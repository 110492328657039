import axios from 'axios'
import { URL } from 'config/config'
import { noop } from 'lodash'
import { customer_service } from '../constants/CustomerService.constants';
import { boardMode, eventStatus } from '../constants'
import { fetchServiceOptionsByBusinessAdminId } from '../actions/serviceOptions'
import { fetchProvidersByBusinessAdminId } from './providerOptions'
import { showAlert } from './alert'

const setVerifyBookingCodeLoading = payload => ({
  type: customer_service.VERIFY_BOOKING_CODE_LOADING,
  payload
});

export const setVerifyBookingCodeSuccess = payload => ({
  type: customer_service.VERIFY_BOOKING_CODE_SUCCESS,
  payload
});

const setVerifyBookingCodeFailure = () => ({
  type: customer_service.VERIFY_BOOKING_CODE_FAILURE
});

const setVerifyMobileNumberLoading = payload => ({
  type: customer_service.VERIFY_MOBILE_DATA_LOADING,
  payload
});

export const setVerifyMobileNumberSuccess = payload => ({
  type: customer_service.VERIFY_MOBILE_DATA_SUCCESS,
  payload
});

const setVerifyMobileNumberFailure = () => ({
  type: customer_service.VERIFY_MOBILE_DATA_FAILURE
});

const setBlackListedCustomers = (payload) => ({
  type: customer_service.BLACKLISTED_CUSTOMERS,
  payload
});
const setBlackListedCustomersLoading = (payload) => ({
  type: customer_service.BLACKLISTED_CUSTOMERS_LOADING,
  payload
});

export const retriggerBookingMails = (data) =>async dispatch => {
  const resp = await axios.post(`${URL.EMAIL_RETRIGGER}`, data)
  if(resp &&  resp.status === 200 && resp.data.success){
    //dispatch(showAlert('success', 'Successfully retriggered emails!'));
    dispatch(showAlert('success', resp.data.message));
  } else {
    dispatch(showAlert('error', resp.data.message));
  }
}

export const verifyBookingCode = (bookingCode) => async dispatch => {
  let data = false
  if (!!bookingCode) {
    dispatch(setVerifyBookingCodeLoading(true));
    const resp = await axios.get(`${URL.VERIFY_BOOKING_CODE}/${bookingCode}/admin/`)
    if (resp && resp.status === 200 && resp.data.success) {
      dispatch(setVerifyBookingCodeSuccess(resp.data.object))
      data = resp.data.object
    } else {
      dispatch(setVerifyBookingCodeFailure())
      data = false
    }
  }
  dispatch(setVerifyBookingCodeLoading(false))
  return data 
}

export const verifyByPhoneNumber = (phoneNumber, cb) => async dispatch => {
  if (!!phoneNumber) {
    dispatch(setVerifyMobileNumberLoading(true));
    const resp = await axios.get(`${URL.VERIFY_BY_PHONENUMBER}/admin/${phoneNumber}`)
    if (resp && resp.status === 200 && resp.data.success) {
      dispatch(setVerifyMobileNumberSuccess(resp.data.object))
      cb(resp.data.object);
    } else {
      dispatch(setVerifyMobileNumberFailure())
    }
  }
  dispatch(setVerifyMobileNumberLoading(false))
}

export const updatePaymentStatus = (event, paymentStatus) => async dispatch => {
  let data = false
  if (event?.eventId && paymentStatus ) {
    dispatch(setVerifyBookingCodeLoading(true));
    let payload = {
      eventId : event.eventId, 
      paymentStatus : paymentStatus
    }
    const resp = await axios.put(`${URL.UPDATE_EVENT_PAYMENT_STATUS}`, payload)
    if (resp && resp.status === 200 && resp.data.success) {
      data = true
      dispatch(showAlert('success',"Payment status updated successfuly"))
      dispatch(verifyBookingCode(event?.bookingCode));
    } else {
      data = false
      const errMsg = resp?.data?.message ?? "Something went wrong. Failed to update payment status!";
      dispatch(showAlert('error',errMsg))
    }
  }
  dispatch(setVerifyBookingCodeLoading(false))
  return data 
}

export const setUpdateStatusSuccess = payload => ({
  type: customer_service.UPDATE_STATUS_SUCCESS,
  payload
});

export const updateCustomerStatus = (data, cb=noop) => async (dispatch) => {
  dispatch(setVerifyBookingCodeLoading(true))
  let [
    isFromBookingData,
    providerId,
    serviceId
  ] = ['','','']
  if(data.status === 'CANCELED') {
    const options = data?.cancellationData ? { data : data?.cancellationData } : undefined;
    const response = await axios.delete(`${URL.CANCEL_BOOKING_EVENT}/${data.eventId}`, options)
    if(response && response.data.success) {
      return response?.data?.object?.status
    }
    return false
  }

  if (data.isFromBookingData) {
    isFromBookingData = data.isFromBookingData;
    providerId = data.providerId;
    serviceId = data.serviceId;
    delete data.isFromBookingData;
    delete data.providerId;
    delete data.serviceId;
  }

  const response = await axios.put(URL.UPDATE_CUSTOMER_FLOW_STATUS, data)
  if (response && response.data.success && isFromBookingData) {
    dispatch(setUpdateStatusSuccess({ ...response.data, isFromBookingData }))
    cb()
  }
  dispatch(setVerifyBookingCodeLoading(false));
  if (response && response.data.success && response.data.object.status) {
    dispatch(setVerifyBookingCodeSuccess(response.data.object))
    return response.data.object.status
  }
  return false
}

export const reEntryOrLateEntry = (bookingCode, cb) => (dispatch) => {
  dispatch(setVerifyBookingCodeLoading(true));
  return axios.post(`${URL.RE_ENTRY_OR_LATE_ENTRY}/${bookingCode}/`)
    .then(({ data }) => {
      if (data.success) {
          dispatch(setVerifyBookingCodeSuccess(data.object || {}));
          cb(data.object || {});
      } else {
          dispatch(showAlert('error', data.message));
      }
    })
    .catch(err => {
        dispatch(showAlert('error', 'Something Went Wrong'))
    })
    .finally(() => {
      dispatch(setVerifyBookingCodeLoading(false));
    });
};
export const reEntryCheckin = ( eventId, customerId ) => (dispatch) => {
  dispatch(setVerifyBookingCodeLoading(true));
  let payload = { eventId, customerId };
  return axios.post(`${URL.CUSTOMER_CHECKIN}`, payload)
    .then(({ data }) => {
      if (data.success) {
          dispatch(setVerifyBookingCodeSuccess(data.object || {}));
      } else {
          dispatch(showAlert('error', data.message));
      }
    })
    .catch(err => {
        dispatch(showAlert('error', 'Something Went Wrong'))
    })
    .finally(() => {
      dispatch(setVerifyBookingCodeLoading(false));
    });
};

export const stopServiceForToday = (providerId, cb = false) => (dispatch) => {
  dispatch(setVerifyBookingCodeLoading(true));
  return axios.post(`${URL.STOP_THE_SERVICE_FOR_TODAY}/${providerId}/`)
    .then(({ data }) => {
      if (data.success) {
          if(cb){
            cb(data);
          }else{
            dispatch(showAlert('success', 'Success'));
          }
      } else {
          dispatch(showAlert('error', data.message));
      }
    })
    .catch(err => {
        dispatch(showAlert('error', 'Something Went Wrong'))
    })
    .finally(() => {
      dispatch(setVerifyBookingCodeLoading(false));
    });
};



const setFetchFlowBoardLoading = payload => ({
  type: customer_service.FETCH_FLOW_BOARD_LOADING,
  payload
});

export const setFetchFlowBoardSuccess = payload => ({
  type: customer_service.FETCH_FLOW_BOARD_SUCCESS,
  payload
});

export const fetchFlowBoard = (data) => dispatch => {

  return axios.post(URL.FETCH_CUSTOMER_FLOW_BOARD, data)
    .then(res => {
      if (res && res.status === 200 && res.data.success) {
        if (res.data.object && res.data.object.customerFlowDetailList) {
          dispatch(setFetchFlowBoardSuccess(res.data.object))
        }
      }
    })
};

export const fetchFlowBoardWithoutFilter = (data) => dispatch => {
  dispatch(setFetchFlowBoardLoading(true));

  return axios.post(URL.FETCH_CUSTOMER_FLOW_BOARD, data)
    .then(res => {
        dispatch(setFetchFlowBoardSuccess(res.data.object));
    })
    .finally(() => {
      dispatch(setFetchFlowBoardLoading(false));
    });
};

export const fetchQueueForProvider = async (providerId) => {
  const response = await axios.get(URL.FETCH_QUEUE_FOR_PROVIDER + `/${providerId}`)
  if(response) {
    return response.data.object
  }
  else
    return {}
}

export const fetchQueueForCounter = async (counterId) => {
  const response = await axios.get(URL.FETCH_QUEUE_FOR_COUNTER + `/${counterId}`)
  if(response) {
    return response.data.object
  }
  else
    return {}
}

export const updateGuestInfo = (payload, bookingCode) => dispatch => {
  dispatch(setVerifyBookingCodeLoading(true));
  return axios.put(URL.USER, payload)
    .then(res => {
      if (res && res.data.success) {
        dispatch(verifyBookingCode(bookingCode));
        dispatch(showAlert('success', 'Successfully updated guest user info!'));
      } else {
        dispatch(showAlert('error', 'Updating guest user info failed!'));
      }
    });
}

export const sendPrescription = ({
  diagnosisString,
  adviceString,
  eventId,
  nameString,
  ageString,
}) => async (dispatch) => {

  try {
    const data = {
      diagnosis : diagnosisString,
      advice : adviceString,
      patientName : nameString,
      age : ageString,
      eventId,
    }
    const result = await axios.post(URL.SEND_PRESCRIPTION, data)
    if(result.data.success)
      dispatch(showAlert('success', 'Prescription Sent'))
    else
      dispatch(showAlert('error', "Couldn't sent Prescription"))
    return (result.data.success)
  }
  catch(error) {
    dispatch(showAlert('error', error))
  }
}

export const addBlackListedCustomer = (payload) => async dispatch => {
  try {
    if (payload?.reason && payload?.customerId) {
      const resp = await axios.post(`${URL.CUSTOMER_BLACKLIST}`, payload)
      if (resp && resp.status === 200 && resp.data.success) {
        dispatch(showAlert('success', 'Succesfuly added to blacklist'))
        dispatch(getBlackListedCustomers())
        return resp.data.object
      } else {
        if(resp?.data?.message)
          dispatch(showAlert('error', resp.data.message))
        else
         dispatch(showAlert('error', "Failed to add on blacklist"))
      }
    }
  }catch(error) {
    dispatch(showAlert('error', error))
  }
}
export const getBlackListedCustomers = (filter) => async dispatch => {
  try {
    dispatch(setBlackListedCustomersLoading(true));
    const resp = await axios.get(`${URL.CUSTOMER_BLACKLIST}`)
    if (resp && resp.status === 200 && resp.data.success) {
      dispatch(setBlackListedCustomers(resp.data.objects || []))
    } else {
    }
    dispatch(setBlackListedCustomersLoading(false));
    return resp?.data?.objects
  }catch(error) {
    dispatch(showAlert('error', error))
    dispatch(setBlackListedCustomersLoading(false));
  }
}

export const removeBlackListedCustomer = (blacklistId) => async dispatch => {
  try {
    dispatch(setBlackListedCustomersLoading(true));
    const resp = await axios.delete(`${URL.CUSTOMER_BLACKLIST}/${blacklistId}`)
    if (resp && resp.status === 200 && resp.data.success) {
      dispatch(showAlert('success', 'Succesfuly removed from blacklist'))
      dispatch(getBlackListedCustomers())
      return resp.data.object
    } else {
      if(resp?.data?.message)
          dispatch(showAlert('error', resp.data.message))
        else
          dispatch(showAlert('error', 'Failed to remove from blacklist'))
    }
    dispatch(setBlackListedCustomersLoading(false));
  }catch(error) {
    dispatch(setBlackListedCustomersLoading(false));
    dispatch(showAlert('error', error))
  }
}


