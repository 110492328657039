import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
    FormLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Switch,
    Tooltip,
    Grid,
    FormControl
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

import InfoIcon from '@material-ui/icons/Info';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CustomPhoneNumberInput from 'components/CustomPhoneNumberInput/CustomPhoneNumberInput';
import ImageUpload from 'components/custom/ImagePicker/ImageUpload';
import LocationPicker from 'components/Location/LocationPicker.js';
import WorkingHoursPicker from 'components/custom/WorkingHoursPicker/WorkingHoursPicker';
import { roseColor } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';

import { defaultReportTime, defaultWorkingHoursObj } from 'constants.js';
import { fetchBusinessCategories } from 'actions/businessCategories';
import { fetchTimezoneOptions } from 'actions/timezoneOptions';
import { isUniqueOrganizationName } from 'actions/organization';
//import moment from 'moment';
import moment from 'moment-timezone';
import zones from 'moment-timezone/data/meta/latest.json';
import slide_default from 'assets/img/slide_default.jpg';
import your_logo from 'assets/img/your_logo.png';

import { makeStyles } from "@material-ui/core/styles";
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormStyle';
const useStyles = makeStyles(validationFormStyle);

const OrganizationCreate = (props, itemRef) => {

    const classes = useStyles();
    const [timeZone, setTimeZone] = useState("");
    const [country, setCountry] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const dispatch = useDispatch();
    const businessCategories = useSelector(state => state.businessCategory.businessCategories);
    const timeZoneOptions = useSelector(state => state.options.timezone.tzOptions);
    const userDetail = useSelector(state => state.user.userDetail);

    const dispatchFetchTimezoneOptions = () => dispatch(fetchTimezoneOptions());
    const dispatchFetchBusinessCategories = () => dispatch(fetchBusinessCategories());
    const dispacthcIsUniqueOrganizationName = (payload) => dispatch(isUniqueOrganizationName(payload));

    useEffect(() => {
        let timeZone_ = moment.tz.guess();
        if (timeZone_ === 'Asia/Calcutta') {
            timeZone_ = 'Asia/Kolkata';
        }
        setTimeZone(timeZone_);
        dispatchFetchBusinessCategories();
        dispatchFetchTimezoneOptions();
    }, [])

    useEffect(() => {
        if (timeZone) {
            let country_ = '';
            if (
                zones.zones[timeZone] &&
                zones.zones[timeZone]['countries'] &&
                zones.zones[timeZone]['countries'][0]
            ) {
                country_ = zones.zones[timeZone]['countries'][0];
            }
            setCountry(country_);

            if (country_) {
                let countryCode_ = getCountryCallingCode(country_);
                countryCode_ = countryCode_ ? "+" + countryCode_ : "";
                setCountryCode(countryCode_)
            }
        }

    }, [timeZone])

    const OrganizationCreateSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter an organisation name')
            .max(50)
            .min(3)
            .matches(
                /^[a-zA-Z0-9\s!\_'-]+$/,
                'Only alphanumerics spaces and ! \' - _ are allowed'
            )
            .matches(
                /^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/,
                'No trailing empty spaces and only one empty space allowed between non-empty characters'
            ),
        businessCategoryId: Yup.string().required('Please select category'),
        telephone: Yup.string().required('Please enter a valid phone Number'),
        description: Yup.string()
            .min(5, 'Description too short')
            .max(200)
            .required('Please enter a description'),
        preferences: Yup.object({
            dataRetention: Yup.number()
                .max(365, 'value can not be above 365')
                .min(90, 'value can not be below 90'),
        }),
        bookingHorizon: Yup.number().min(0),
        bookingCutOff: Yup.number().min(0),
    });

    const formik = useFormik({
        initialValues: {
            orgMode: 'PROVIDER_BASED',
            businessCategoryId: '',
            preferences: {
                allowListingOnQuezone: false,
                allowReschedule: true,
                allowSmsCommunication: true,
                allowProviderNotification: true,
                allowCustomerNotification: true,
                displayPhoneNumber: true,
                allowReminders: true,
                reminderType: [],
                enableWaitlist: true,
                isCustomerRegistrationRequired: true,
                salesforceIntegration: false,
                preferGuestcheckout: true,
                trackManualTime: true,
                bookingHorizon: 90,
                bookingCutOff: 0,
                dataRetention: 90,
                timezoneId: timeZone,
                reportTime: defaultReportTime,
                enableWhiteLabel : false,
                enableProducts : false,
            },
            workingHours: [...defaultWorkingHoursObj],
            telephone: '',
            orgEmail: '',
            website: '',
            privacyStatement: '',
            businessAdminEmail: '',
            description: '',
            userSub: userDetail.id,
            imagePreviewUrl: your_logo,
            picturePreviewUrl: slide_default,
            geoLocationId: null,
            logo: null,
            advPic: null,
        },
        validationSchema: OrganizationCreateSchema,
        onSubmit: values => { },
        enableReinitialize: true
    });

    const {
        values,
        errors,
        status,
        isValid,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        setTouched,
        setFieldTouched,
        validateForm,
        setFieldError
    } = formik;


    const wizardSubmit = async (allStates) => {
        setTouched({
            name: true,
            telephone: true,
            description: true,
            businessCategoryId: true
        }, true);//touches all fields
        const errors = await validateForm();
        const errorFields = Object.keys(errors);
        let payload = formik.values;
        console.log("wizard Submit OrgCreate validateForm >>", errors)
        console.log("wizard Submit OrgCreate payload >>", payload)

        const isUniqueOrgName = await dispacthcIsUniqueOrganizationName(payload);
        if (!isUniqueOrgName) {
            setFieldError("name", "Already registered organization. Please try different name")
            return false;
        }

        const updatedStates = {
            ...allStates,
            organizationPayload: payload
        }
        const resp = errorFields?.length ? false : updatedStates;
        return resp;
    }
    const handleImageChange = (e) => {
        console.log("handleImageChange", e);
        setFieldValue("logo", e)
    }

    const handleMarketingPicture = (e) => {
        console.log("handleMarketingPicture", e);
        setFieldValue("advPic", e)
    }

    useImperativeHandle(itemRef, () => ({
        wizardSubmit: wizardSubmit
    }))

    return (
        <>
            <GridItem xs={12} sm={12} md={12}>
                <form>
                    <Card>
                        {/*
                        <CardHeader color="rose" text>
                            <CardText color="rose">
                                <h4 className={classes.cardTitle}>Create Organization</h4>
                            </CardText>
                        </CardHeader>
                        */}
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <CustomInput
                                        id="name"
                                        inputProps={{
                                            placeholder: 'Name',
                                            name: "name",
                                            type: 'text',
                                            maxLength: '50',
                                            onChange: handleChange,
                                            value: values?.name?.trimStart() ?? ''
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                    {errors.name && touched.name ? (
                                        <div id="name-error" style={{ color: 'red', position: 'relative' }}>
                                            {errors.name}
                                        </div>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Phone Number
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <PhoneInput
                                        id="telephone"
                                        placeholder={"e.g. " + countryCode + " 3 xxxx xxxx"}
                                        country={country}
                                        name={'telephone'}
                                        value={values['telephone']}
                                        onChange={(e) => setFieldValue('telephone', e)}
                                        inputComponent={CustomPhoneNumberInput}
                                    />
                                    {errors.telephone && touched.telephone ? (
                                        <div id="telephone-error" style={{ color: 'red', position: 'relative' }}>
                                            {errors.telephone}
                                        </div>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Website
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <CustomInput
                                        id="website"
                                        inputProps={{
                                            placeholder: 'Website',
                                            type: 'text',
                                            onChange: handleChange,
                                            value: values.website
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Organization Email
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <CustomInput
                                        id="orgEmail"
                                        inputProps={{
                                            placeholder: 'Email',
                                            type: 'text',
                                            onChange: handleChange,
                                            value: values.orgEmail
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Business Category
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <Select
                                            //fullWidth
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={values.businessCategoryId}
                                            onChange={handleChange('businessCategoryId')}
                                            inputProps={{
                                                name: "businessCategoryId",
                                                id: "businessCategoryId"
                                            }}
                                        >
                                            {businessCategories.map((business) => (
                                                <MenuItem
                                                    key={business.id}
                                                    value={business.id}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                >
                                                    {business.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors.businessCategoryId &&
                                        touched.businessCategoryId ? (
                                        <div
                                            id="businessCategoryId-error"
                                            style={{ color: 'red', position: 'relative' }}
                                        >
                                            {errors.businessCategoryId}
                                        </div>
                                    ) : null}
                                </GridItem>
                                {/* <GridItem xs={4} sm={4} md={2}></GridItem>
                                    <GridItem xs={8} sm={8} md={4}></GridItem>
                                 */}
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Description
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <CustomInput
                                        id="description"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            multiline: true,
                                            inputProps: { maxLength: 200 },
                                            value: values.description,
                                            onChange: handleChange
                                        }}
                                    />
                                    {errors.description && touched.description && (
                                        <div id="description-error" style={{ color: 'red', position: 'relative' }}>
                                            {errors.description}
                                        </div>
                                    )}
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2} lg={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Organization Location
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={10} lg={8}>
                                    <LocationPicker
                                        onChange={handleChange}
                                        currentLocationId={values.geoLocationId}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={2}>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Time Zone
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <Select
                                            value={values.preferences.timezoneId}
                                            fullWidth
                                            name='preferences.timezoneId'
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            inputProps={{
                                                name: 'preferences.timezoneId',
                                                id: 'preferences.timezoneId'
                                            }}
                                            onChange={handleChange}
                                        >
                                            {timeZoneOptions.map((tz) => (
                                                <MenuItem
                                                    key={tz.value}
                                                    value={tz.label}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                >
                                                    {tz.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridContainer style={{ marginVertical: 30 }}>
                                    <GridItem xs={12} sm={3} md={3} lg={2}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Working Hours
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={9} md={9} lg={10}>
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <WorkingHoursPicker
                                                id="orgWh"
                                                workingHours={values.workingHours}
                                                onChange={(workingHours_) => setFieldValue("workingHours", [...workingHours_])}
                                            />
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel
                                        className={classes.labelHorizontal}
                                    >
                                        Organization Logo
                                        <Tooltip
                                            title="Image Aspect ratio should be 1:1, shall not exceed resolution of 2048x2048"
                                        >
                                            <InfoIcon
                                                style={{ width: '.9rem' }}
                                            />
                                        </Tooltip>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <ImageUpload
                                            currentPictureUrl={values.imagePreviewUrl}
                                            aspectRatio={1 / 1}
                                            localImageName={'imageObject'}
                                            onPictureChange={handleImageChange}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={2}>
                                    <FormLabel
                                        className={classes.labelHorizontal}
                                    >
                                        Select your marketing picture&nbsp;
                                        <Tooltip
                                            title="Image Aspect ratio should be 16:9, shall not exceed resolution of 2048x1152"
                                        >
                                            <InfoIcon
                                                style={{ width: '.9rem' }}
                                            />
                                        </Tooltip>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={4}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <ImageUpload
                                            currentPictureUrl={values.picturePreviewUrl}
                                            aspectRatio={16 / 9}
                                            localImageName={'pictureObject'}
                                            onPictureChange={handleMarketingPicture}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                        </CardFooter>
                    </Card>
                </form>
            </GridItem>
        </>
    )

}
export default forwardRef(OrganizationCreate);