import axios from 'axios';
import { provider } from 'constants/Provider.constants';
import { URL } from 'config/config';

export const fetchTimezones = () => {
  return dispatch => {
    dispatch({ type: provider.FETCH_TIMEZONES_LOADING });
    axios.get(URL.TIMEZONE)
      .then(({ data }) => {
        dispatch({
          type: provider.FETCH_TIMEZONES_SUCCESS,
          payload: data.objects
        });
      })
      .catch(err => {
        dispatch({
          type: provider.FETCH_TIMEZONES_FAILURE,
          payload: err
        });
      });
  };
};

export function fetchProvidersLoading() {
  return {
    type: provider.FETCH_PROVIDERS_LOADING
  };
}

export function fetchProvidersSuccess(providers) {
  return {
    type: provider.FETCH_PROVIDERS_SUCCESS,
    payload: { providers }
  };
}

export function fetchCustomersSuccess(customers){
  return {
    type: provider.FETCH_CUSTOMER_SUCCESS,
    payload: { customers } 
  };
}

export function fetchAdminsSuccess(admins) {
  return {
    type: provider.FETCH_ADMINS_SUCCESS,
    payload: { admins }
  };
}

export function fetchProvidersFailure(error) {
  return {
    type: provider.FETCH_PROVIDERS_FAILURE,
    payload: { error }
  };
}

export function fetchProvidersByOrdId(orgId) {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_BY_ORG_ID}/${orgId}`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}

export function fetchProvidersOptionByServiceProviderId(serviceProviderId) {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_SERVICE_PROVIDER_ID}/${serviceProviderId}`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}

export function fetchProvidersOptionByOrdId(orgId) {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_ORG_ID}/${orgId}`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}

export function fetchProvidersOptionByServiceId(serviceId) {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_SERVICE_ID}/${serviceId}`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure(data));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}
export function fetchProvidersByBusinessAdminId() {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_BY_BUSINESS_ADMIN_ID}/`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}
export function fetchProvidersBySuperAdminId() {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_PROVIDERS_BY_SUPER_ADMIN}/`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchProvidersSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}
export function fetchCustomersBySuperAdmin() {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_CUSTOMERS_BY_SUPER_ADMIN}/`)
    .then(({data})=>{
      if(data.objects) {
        dispatch(fetchCustomersSuccess(data.objects));
      } else {
        dispatch(fetchProvidersFailure('Topology error'));
      }
      return data;
    })
    .catch(err => dispatch(fetchProvidersFailure(err)));
  }
}
export function fetchAdminsByBusinessAdminId() {
  return dispatch => {
    dispatch(fetchProvidersLoading());
    axios.get(`${URL.FETCH_ADMINS_BY_BUSINESS_ADMIN_ID}/`)
      .then(({ data }) => {
        if (data.objects) {
          dispatch(fetchAdminsSuccess(data.objects));
        } else {
          dispatch(fetchProvidersFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProvidersFailure(err)));
  };
}

export const editProviderLoading = () => {
  return {
    type: provider.EDIT_PROVIDER_LOADING
  };
};

export const editProviderSuccess = data => {
  return {
    type: provider.EDIT_PROVIDER_SUCCESS,
    payload: data.object
  };
};

export const editProviderFailure = error => {
  return {
    type: provider.EDIT_PROVIDER_FAILURE,
    payload: error
  };
};

export const deleteProvider = providerId => {
  return dispatch => {
    dispatch({ type: provider.DEL_PROVIDER_LOADING });
    axios.delete(`${URL.USER}/${providerId}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: provider.DEL_PROVIDER_SUCCESS,
            payload: data.object
          });
          dispatch(fetchProvidersByBusinessAdminId());
        } else {
          dispatch({
            type: provider.DEL_PROVIDER_FAILURE,
            payload: data
          });
        }
      });
  };
};

export const resetProvider = providerId => {
  console.log(providerId);
  return dispatch => {
    dispatch({ type: provider.EDIT_PROVIDER_LOADING });
    axios.get(`${URL.ADMIN_RESET_PASSWORD}/${providerId}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: provider.EDIT_PROVIDER_SUCCESS,
            payload: data.object
          });
          dispatch(fetchProvidersByBusinessAdminId());
        } else {
          dispatch({
            type: provider.EDIT_PROVIDER_FAILURE,
            payload: data
          });
        }
      });
  };
};

export function editProvider(values, history) {
  return dispatch => {
    dispatch(editProviderLoading());
    axios.put(URL.AWS_USER, values)
      .then(({ data }) => {
        if (data.success) {
          history.goBack();
        } else {
          dispatch(editProviderFailure(data));
        }
      })
      .catch(err => {
        dispatch(editProviderFailure(err));
      });
  };
}

export function createProviderSuccess(providers) {
  return {
    type: provider.CREATE_PROVIDER_SUCCESS,
    payload: { providers }
  };
}

export function createProviderFailure(error) {
  return {
    type: provider.CREATE_PROVIDER_FAILURE,
    payload: { error }
  };
}

export function createProvider(values, history, isWizard, wizardSubmitCallback) {
  return dispatch => {
    dispatch({ type: provider.CREATE_PROVIDER_LOADING });
    return axios.post(URL.ADMIN_CREATE_AWS_USER, values)
      .then(({ data }) => {
        if (data.success) {
          dispatch(createProviderSuccess(data));
            
          if(history)
            history.push('/provider/list');
              
        } else {
          dispatch(createProviderFailure(data));
        }
        if (isWizard) {
          return data;
        } 
      })
      .catch(err => dispatch(createProviderFailure(err)));
  };
}

export function fetchProviderLoading() {
  return {
    type: provider.FETCH_PROVIDER_LOADING
  };
}

export function fetchProviderSuccess(data) {
  return {
    type: provider.FETCH_PROVIDER_SUCCESS,
    payload: { data }
  };
}

export function fetchProviderFailure(error) {
  return {
    type: provider.FETCH_PROVIDER_FAILURE,
    payload: { error }
  };
}

export function fetchProvider(id) {
  return dispatch => {
    dispatch(fetchProviderLoading());
    axios.get(`${URL.PROVIDER}/${id}`)
      .then(({ data }) => {
        if (data.object) {
          dispatch(fetchProviderSuccess(data.object));
        } else {
          dispatch(fetchProviderFailure('Topology Error'));
        }
        return data;
      })
      .catch(err => dispatch(fetchProviderFailure(err)));
  };
}

export function createAdmin(values, history) {
  return dispatch => {
    dispatch({ type: provider.CREATE_PROVIDER_LOADING });
    axios.post(URL.ADMIN_CREATE_AWS_USER, values)
      .then(({ data }) => {
        if (data.success) {
          dispatch(createProviderSuccess(data));
          history.push('/admin/list');
        } else {
          dispatch(createProviderFailure(data));
        }
      })
      .catch(err => dispatch(createProviderFailure(err)));
  };
}

export const deleteAdmin = providerId => {
  return dispatch => {
    dispatch({ type: provider.DEL_PROVIDER_LOADING });
    axios.delete(`${URL.USER}/${providerId}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: provider.DEL_PROVIDER_SUCCESS,
            payload: data.object
          });
          dispatch(fetchAdminsByBusinessAdminId());
        } else {
          dispatch({
            type: provider.DEL_PROVIDER_FAILURE,
            payload: data
          });
        }
      });
  };
};

export function editAdmin(values, history) {
  return dispatch => {
    dispatch(editProviderLoading());
    axios.put(URL.AWS_USER, values)
      .then(({ data }) => {
        if (data.success) {
          history.push('/admin/list');
        } else {
          dispatch(editProviderFailure(data));
        }
      })
      .catch(err => {
        dispatch(editProviderFailure(err));
      });
  };
}
