import 'polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import Amplify from 'aws-amplify';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import blueColor from '@material-ui/core/colors/blue';
import pinkColor from '@material-ui/core/colors/pink';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import thunk from 'redux-thunk';

import indexRoutes from 'routes';
import reducers from 'reducers';
import 'utils/apiWrapper.js';
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import 'react-phone-number-input/style.css';
import 'assets/scss/global.scss';
import { RESET_ALL_STATES } from 'actions/common';
import axios from 'axios';
import { API_ROOT, AWS_CONFIG } from 'config/config';
import Alert from 'components/custom/Alert/Alert';
import { roseColor } from 'assets/jss/material-dashboard-pro-react';

axios.defaults.baseURL = API_ROOT;

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

Amplify.configure(AWS_CONFIG);

export const hist = createBrowserHistory();

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk))(createStore);

const store = createStoreWithMiddleware((state, action) => {
  if (action.type === RESET_ALL_STATES) return reducers(undefined, action);
  return reducers(state, action);
})
const theme = createTheme({
  typography: {},
  palette: {
    primary: blueColor,
    secondary: {
      main: roseColor[0],
    }// pinkColor,
  }
})

// load google map script for react-location-picker
//const script = document.createElement('script')
//script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA9uEhbDhCZaOAdCp8mdSEgqoA3NqoAxbs&v=3.exp&libraries=geometry,drawing,places'
//script.async = true
//script.defer = true
//document.body.appendChild(script)


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map(prop => {
              return <Route path={prop.path} component={prop.component} key={prop.path} />;
            })}
          </Switch>
        </Router>
        <Alert />
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

export default store;
