import React, { useEffect, useState, useReducer, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Paper,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Container,
} from '@material-ui/core'
import webstomp from 'webstomp-client'
import SockJS from 'sockjs-client'
import { connect, useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import {
  fetchFlowBoardWithoutFilter,
  setFetchFlowBoardSuccess,
  fetchQueueForProvider,
  fetchQueueForCounter,
} from "actions/customerService"
import { eUserType, eventStatus } from "constants.js"
import { fetchProvidersByBusinessAdminId, setProviderOptionsSuccess } from 'actions/providerOptions'
import { fetchServiceOptionsByBusinessAdminId } from 'actions/serviceOptions.js'
import { fetchCountersByOrganizationId } from 'actions/counter'
import LoadingModal from 'components/CustomModal/LoadingModal.jsx'
import { API_SUBSCRIBE_EVENTS_BOARD } from 'config/config'
import moment from 'moment-timezone'
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle"
import { Shuffle, Stop } from '../../../node_modules/@material-ui/icons/index'
import { findIndex } from 'lodash'

const pageSpecificStyles = {
  cellLargeFont: {
    fontSize: 'xx-large',
    fontWeight: '500 !important',
    textAlign: 'center'
  },
  cellHeaderBold: {
    fontSize: 'x-large',
    textAlign: 'center'
  },
  greenHighlight: {
    color: 'green',
  },
  blueHighlight: {
    color: 'blue',
  },
}


const TvDisplay = ({ classes }) => {

  const userDetail = useSelector(state => state.user.userDetail);
  const fetchUserLoading = useSelector(state => state.user.fetchUserLoading);
  const isProviderLoading = useSelector(state => state.options.provider.isLoading);
  const providerOptions = useSelector(state => state.options.provider.providerOptions);
  const boardData = useSelector(state => state.customerService.boardData);
  const isBoardLoading = useSelector(state => state.customerService.isBoardLoading);
  const serviceOptions = useSelector(state => state.options.service.serviceOptions);

  const dispatch = useDispatch();

  const currentUnixTimeStamp = moment().unix()

  const client = useRef(webstomp.over(new SockJS(API_SUBSCRIBE_EVENTS_BOARD), { debug: false }))

  const [counters, setCounters] = useState([])
  const [queOptions, setQueOptions] = useState([]);
  const [selectedQueOption, setSelectedQueOption] = useState("none");
  const [queue, setQueue] = useState([]);
  const [autoSwitchProviders, setAutoSwitchProviders] = useState(false);
  const [forceRerender, setForceRerender] = useState(0);

  const subscription = useRef(false)

  useEffect(() => {
    const cycleSelectedQueuOption = () => {
      if (autoSwitchProviders) {
        setSelectedQueOption(current => {
          const index = queOptions.findIndex(item => item?.id == current)
          const newIndex = (index > -1 && index < queOptions.length - 1) ? index + 1 : 0;
          return queOptions[newIndex] ? queOptions[newIndex].id : "none"
        })
      }
    }
    const timeIterval = setInterval(cycleSelectedQueuOption, 7000);
    return () => {
      clearInterval(timeIterval)
    }
  }, [autoSwitchProviders])

  useEffect(() => {
    let options = [];
    options = [...options, ...providerOptions.map(item => ({ id: item.id, label: `${item.givenName} ${item.familyName}`, type: "provider" }))];
    options = [...options, ...counters.map(item => ({ id: item.id, label: `Counter: ${item.name}`, type: "counter" }))];
    setQueOptions(options);

  }, [providerOptions, counters])

  useEffect(() => {
    if (selectedQueOption) {
      onChangeProviderOrService(selectedQueOption)
    }
  }, [selectedQueOption])


  useEffect(() => {
    const fetchCounters = async () => {
      setCounters(await fetchCountersByOrganizationId(providerOptions[0].providerInformation.organizationId))
    }
    if (providerOptions.length > 0) {
      fetchCounters()
    }
  }, [providerOptions])

  useEffect(
    () => {
      if (!(userDetail.providerInformation && userDetail.providerInformation.isAdmin) && userDetail.userType === eUserType.provider) {
        dispatch(setProviderOptionsSuccess([{ label: userDetail.fullName, value: userDetail.id }]))
      } else {
        dispatch(fetchProvidersByBusinessAdminId());
      }
      //dispatch(fetchServiceOptionsByBusinessAdminId());
      client.current.connect({}, () => onChangeProviderOrService(selectedQueOption))
      return (
        () => {
          if (subscription.current)
            subscription.current.unsubscribe()
          if (client.current.connected)
            client.current.disconnect()
        }
      )
    }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setForceRerender(prev => prev + 1)
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);


  const localTimezone = moment.tz.guess()
  const startTime = useRef(moment.tz(localTimezone.current).startOf('day').unix())
  const endTime = useRef(moment.tz(localTimezone.current).endOf('day').unix())

  function transformQueueArray(queueArray) {
    //inorder to group tokens into array by orginalstartTime for handling parallel customers
    const resultMap = new Map();
    queueArray.forEach(item => {
      const { token, originalStartTime } = item;

      if (!resultMap.has(originalStartTime)) {
        resultMap.set(originalStartTime, { ...item, token: [token], originalStartTime });
      } else {
        resultMap.get(originalStartTime).token.push(token);
      }
    });
    // Convert the map values back to an array
    const resultArray = Array.from(resultMap.values());
    return resultArray;
  }

  const getFlowBoardData = async (providerInput = false) => {
    console.log("getFlowBoardData >> ", providerInput)
    let fetchedQueue = []
    if (providerInput && !providerOptions.map(element => element.id).includes(providerInput)) {
      fetchedQueue = await fetchQueueForCounter(providerInput)
    } else {
      const selectedProvider = providerInput ? providerInput : selectedQueOption
      let data = {
        providerIds: [selectedProvider],
        startTime: startTime.current,
        endTime: endTime.current,
      }
      dispatch(fetchFlowBoardWithoutFilter(data));
      fetchedQueue = await fetchQueueForProvider(selectedProvider)
    }
    setQueue(transformQueueArray(fetchedQueue.queueActual))
  }

  const triggerDisplayUpdate = (message, providerId) => {
    // due to server triggering the websocket update request too early before db call has been processed
    setTimeout(() => getFlowBoardData(providerId), 1500)
  }

  const subscribeEventByProvider = (queueIdentificationValue) => {
    if (client.current.connected) {
      if (subscription.current) {
        subscription.current.unsubscribe()
      }
      subscription.current = client.current.subscribe(`/liveupdates/board/queue/${queueIdentificationValue}`, (message) => triggerDisplayUpdate(message, queueIdentificationValue))
    }
  }

  const onChangeProviderOrService = (value) => {
    if (value && value !== 'none') {
      subscribeEventByProvider(value)
      getFlowBoardData(value)
    }
  }

  const toggleAutoSwitchProvider = () => {
    setAutoSwitchProviders(prev => !prev);
  }

  const todayDate = moment(Date.now()).format('dddd DD MMM YYYY')

  const isLoading = (isProviderLoading || fetchUserLoading)

  const calculateTimeLeftForAppointment = (booking, currentUnixTime) => {

    const currentBooking = queue.find((item, index) => index == 0);
    let currentEventStartTime = currentBooking?.startedTime ?? currentBooking?.originalStartTime ?? currentUnixTime;
    let currentEventExpectedCompleteTime = currentEventStartTime + currentBooking?.duration ?? 0

    let currentEventDelay = (currentBooking.status == "CHECKED_IN" && currentBooking?.originalStartTime < currentUnixTime) ?
      (currentUnixTime - currentEventStartTime)
      : (currentEventExpectedCompleteTime < currentUnixTime) ?
        (currentUnixTime - currentEventExpectedCompleteTime) : 0;

    let durationOfPrecedingCustomerFlows = 0;
    let indexInQueue = queue.findIndex((item) => item.eventId == booking.eventId || item.originalStartTime == booking.originalStartTime);
    for (let index = 0; index < indexInQueue; index++) {
      durationOfPrecedingCustomerFlows += queue[index].duration
    }

    let ETA = currentEventStartTime + currentEventDelay + durationOfPrecedingCustomerFlows;
    if (ETA < booking?.originalStartTime) {
      ETA = booking?.originalStartTime;
    }
    return ETA
  }
  const getAppointmentTimeString = (booking) => {
    let output = moment(calculateTimeLeftForAppointment(booking, currentUnixTimeStamp) * 1000).format('h : mm A');
    return output;
  }


  const nextSlotAboutToStart = 0 // queue.findIndex(event => (event.status === eventStatus.checkedIn))

  const getCurrentTokens = () => {
    const startedBookingTokens = queue.filter(event => (event.status === eventStatus.started)).map(item => item?.token);
    return startedBookingTokens.length ? startedBookingTokens : []
  }

  return (
    <>
      <LoadingModal
        openModal={isLoading}
      />
      <Container maxWidth='xl'>
        {
          providerOptions && providerOptions.length > 0 ?
            (
              <>
                <GridContainer justifyContent="space-between" style={{ padding: "0px 25px" }}>
                  <GridItem>
                    <GridContainer alignItems="center">
                      <GridItem>
                        <Select
                          name="queueuOptions"
                          onChange={event => setSelectedQueOption(event.target.value)}
                          value={selectedQueOption}
                          style={{ color: '#fff', padding: '5px', minWidth: 100, fontSize: 'xx-large' }}
                        >
                          <MenuItem value="none" disabled>
                            Select
                          </MenuItem>
                          {
                            queOptions.map(item => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.label}
                              </MenuItem>
                            ))
                          }
                        </Select>
                        <div style={{ fontWeight: 'bold', fontSize: 'x-large', padding: '10px' }}>
                          {boardData?.providerName}
                        </div>
                        {boardData && boardData.serviceName &&
                          <div style={{ fontWeight: 'bold', fontSize: 'x-large', padding: '10px' }}>
                            {boardData.serviceName}
                          </div>
                        }
                      </GridItem>
                      <GridItem>
                        <IconButton
                          onClick={toggleAutoSwitchProvider}
                          style={{ color: "#fff" }}
                        >
                          {autoSwitchProviders ? <Stop /> : <Shuffle />}
                        </IconButton>
                      </GridItem>
                    </GridContainer>

                  </GridItem>
                  <GridItem>
                    <Typography variant="h6" style={{ fontSize: 'xx-large' }}>
                      {todayDate}
                    </Typography>
                    <Typography variant="h6" style={{ fontSize: 'x-large', textAlign: 'right' }}>
                      {moment(currentUnixTimeStamp * 1000).format('h : mm A')}
                    </Typography>
                  </GridItem>
                </GridContainer>
                {
                  queue && queue.length > 0 ?
                    <GridContainer alignItems="center">
                      {getCurrentTokens().length ?
                        <GridItem style={{ flex: 1 }}>
                          <Paper style={{}}>
                            <GridContainer justifyContent="center">
                              <GridItem>
                                <Card style={{ padding: 30 }}>
                                  <Typography variant="h4" style={{ textAlign: 'center' }}>TOKEN</Typography>
                                  <Typography variant="h1" style={{ textAlign: 'center', fontWeight: 'bold' }}>{getCurrentTokens().join(",")}</Typography>
                                </Card>

                              </GridItem>
                            </GridContainer>
                          </Paper>
                        </GridItem>
                        : null
                      }
                      <GridItem style={{ flex: 1, maxHeight: '85vh', overflow: 'scroll' }}>
                        <Paper>
                          <Table
                            elevation={2}
                          >
                            <TableHead
                            >
                              <TableRow
                                classes={{ root: classes.row }}
                              >
                                <TableCell
                                  className={classes.cellHeaderBold}
                                >
                                  Token
                                </TableCell>
                                <TableCell
                                  className={classes.cellHeaderBold}
                                >
                                  Expected Time of Appointment
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                queue.filter(item => [eventStatus.checkedIn].includes(item.status)).map(
                                  (booking, index) => (
                                    <TableRow
                                      key={booking.bookingCode}
                                      classes={{ root: classes.row }}
                                    >
                                      <TableCell
                                        className={`${classes.cellLargeFont} ${nextSlotAboutToStart === index && classes.greenHighlight} ${booking.status === eventStatus.started && classes.blueHighlight}`}
                                      >
                                        {booking?.token?.join(",")}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.cellLargeFont} ${nextSlotAboutToStart === index && classes.greenHighlight} ${booking.status === eventStatus.started && classes.blueHighlight}`}
                                      >
                                        {
                                          (booking.status === eventStatus.started) ?
                                            'ONGOING'
                                            :
                                            (nextSlotAboutToStart === index ? 'NEXT' : getAppointmentTimeString(booking))
                                        }
                                      </TableCell>
                                    </TableRow>
                                  )
                                )
                              }
                            </TableBody>
                          </Table>
                        </Paper>
                      </GridItem>
                    </GridContainer>
                    :
                    <Paper>
                      <div className={classes.content}>
                        <div className={classes.container}>
                          <GridContainer justifyContent="center">
                            <GridItem xs={12} sm={6} md={4}>
                              <Card className={classes.root} variant="outlined">
                                <CardContent style={{
                                  height: '45vh',
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                  <Typography variant="h4" component="h4"
                                    style={{
                                      textAlign: "center", margin: "auto",
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    No Bookings Available
                                  </Typography>
                                </CardContent>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </Paper>
                }
              </>
            )
            :
            (
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>
                  <Card className={classes.root} variant="outlined">
                    <CardContent style={{
                      height: '45vh',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Typography variant="h4" component="h4"
                        style={{
                          textAlign: "center", margin: "auto",
                          fontWeight: 'bold'
                        }}
                      >
                        No Providers Available
                      </Typography>
                    </CardContent>
                  </Card>
                </GridItem>
              </GridContainer>
            )
        }
      </Container>
    </>
  )
}


export default withStyles(
  theme => ({
    ...registerPageStyle,
    ...tableStyle(theme),
    ...pageSpecificStyles,
  })
)(TvDisplay)

