import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Tooltip,
  Grid,
  FormControl,
  TextField,
  Radio,
  Icon,
  Box
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import InfoIcon from '@material-ui/icons/Info';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CustomPhoneNumberInput from 'components/CustomPhoneNumberInput/CustomPhoneNumberInput';
import ImageUpload from 'components/custom/ImagePicker/ImageUpload';
import LocationPicker from 'components/Location/LocationPicker.js';
import WorkingHoursPicker from 'components/custom/WorkingHoursPicker/WorkingHoursPicker';
import { roseColor } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';

import { defaultReportTime, defaultWorkingHoursObj } from 'constants.js';
import { createService, fetchServiceCategories } from './../../../actions/service';
//import moment from 'moment';
import moment from 'moment-timezone';
import zones from 'moment-timezone/data/meta/latest.json';
import defaultImage from 'assets/img/service-defaultimage.jpg';
import { serviceMode } from '../../../constants';

import { makeStyles } from "@material-ui/core/styles";
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormStyle';
import IconPicker from 'components/custom/IconPicker/IconPicker';
const useStyles = makeStyles(validationFormStyle);

const ServiceCreate = (props, itemRef) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryOptions = useSelector(state => state.service.serviceCategories);
  const dispatchFetchServiceCategories = () => dispatch(fetchServiceCategories());

  useEffect(() => {
    dispatchFetchServiceCategories();
  }, [])


  const ServiceCreateSchema = Yup.object().shape({
    serviceCategoryId: Yup.string()
      .required("This field is required"),
    serviceCategoryName: Yup.string()
      .required("This field is required")
      .matches(
        /^[a-zA-Z0-9\s!\_'-]+$/,
        'Only alphanumerics spaces and ! \' - _ are allowed'
      )
      .matches(
        /^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/,
        'No trailing empty spaces and only one empty space allowed between non-empty characters'
      ),
    name: Yup.string()
      .min(3, "Name too short")
      .max(50, "Name too long")
      .required("This field is required")
      .matches(
        /^[a-zA-Z0-9\s!\_'-]+$/,
        'Only alphanumerics spaces and ! \' - _ are allowed'
      )
      .matches(
          /^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/,
          'No trailing empty spaces and only one empty space allowed between non-empty characters'
      ),
    description: Yup.string()
      .min(5, "Description too short")
      .max(200)
      .required("This field is required"),
    duration: Yup.number()
      .min(1),
    gapBetweenAppointments: Yup.number()
      .min(0)
      .max(600),
    numberOfParallelCustomer: Yup.number()
      .min(1).max(999),
    lateEntryOffset: Yup.number()
      .min(0),
    reEntryOffset: Yup.number()
      .min(0)
  })

  const formik = useFormik({
    initialValues: {
      lateEntryOffset: 0,
      reEntryOffset: 0,
      allowProviderSelection: true,
      isCustomEmail: false,
      name: '',
      description: '',
      duration: 15,
      gapBetweenAppointments: 0,
      mode: "SCHEDULE",
      serviceCategoryId: 'NEED_TO_SET',
      serviceCategoryName: '',
      serviceCategoryIcon: 'gavel',
      tags: '',
      organizationId: 'NEED_TO_SET',
      bookingCutOff: '',
      imagePreviewUrl: defaultImage,
      image: null,
      selectedCounters: [],
    },
    validationSchema: ServiceCreateSchema,
    onSubmit: values => { },
    enableReinitialize: true
  });

  const {
    values,
    errors,
    status,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setTouched,
    setFieldTouched,
    validateForm,
  } = formik;


  const wizardSubmit = async (allStates) => {
    setTouched({
      serviceCategoryId: true,
      serviceCategoryName: true,
      name: true,
      description: true,
      duration: true,
    }, true);//touches all fields

    const errors = await validateForm();
    const errorFields = Object.keys(errors);
    let payload = formik.values;
    //console.log("wizard Submit ServiceCreate payload >>", payload)
    console.log("wizard Submit ServiceCreate validateForm >>", errors)

    const resp = errorFields?.length ? false : payload;
    return resp;
  }

  const handleServicePicture = (e) => {
    console.log("handleImageChange", e);
    setFieldValue("image", e)
  }

  useImperativeHandle(itemRef, () => ({
    wizardSubmit: wizardSubmit
  }))

  const designations = [
    { value: "No designation", label: "No designation" },
    { value: "Mr", label: 'Mr' },
    { value: "Mrs", label: 'Mrs' },
    { value: "Ms", label: 'Ms' },
    { value: "Dr", label: 'Dr' },
  ];

  return (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <form>
          <Card>
            {/* 
              <CardHeader color="rose" text>
                  <CardText color="rose">
                      <h4 className={classes.cardTitle}>Create Provider</h4>
                  </CardText>
              </CardHeader>
            */}
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Service Name</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    id="name"
                    name="name"
                    inputProps={{
                      onChange: handleChange,
                      value: values.name,
                      placeholder: ""
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {errors.name && touched.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Description</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      inputProps: { maxLength: 200 },
                      value: values.description,
                      onChange: handleChange
                    }}
                  />
                  {errors.description && touched.description && (
                    <div style={{ color: "red" }}>{errors.description}</div>
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={4} sm={4} md={4} xl={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Service Category
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={5} md={4} xl={5}>
                  <CustomInput
                    id="serviceCategoryName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      inputProps: { maxLength: 30 },
                      value: values.serviceCategoryName,
                      onChange: handleChange,
                      placeholder: "Eg: Service Department"
                    }}
                  />
                  {errors.serviceCategoryName && touched.serviceCategoryName && (
                    <div style={{ color: "red" }}>{errors.serviceCategoryName}</div>
                  )}
                  {/*
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Autocomplete
                      options={categoryOptions ? categoryOptions : []}
                      getOptionLabel={(option) => option?.name ? option.name : ""}
                      getOptionSelected={(option) => { return option?.id == values?.serviceCategoryId }}
                      value={categoryOptions.length && categoryOptions[categoryOptions.findIndex(option => option.id == values.serviceCategoryId)] || ""}
                      onChange={(event, value) => { console.log(value); setFieldValue('serviceCategoryId', value?.id) }}
                      //style={{ paddingTop: 27, marginBottom: 17 }}
                      classes={{ inputRoot: classes.underline, option: classes.selectMenuItem }}
                      renderInput={(params) =>
                        <TextField {...params} variant="standard" />
                      }
                    />
                  </FormControl>
                  {errors.serviceCategoryId && touched.serviceCategoryId && (
                    <div style={{ color: "red" }}>{errors.serviceCategoryId}</div>
                  )}
                  */}
                </GridItem>
                <GridItem xs={12} sm={3} md={2} xl={1}>
                  <Box className={classes.selectFormControl}>
                    <IconPicker
                      onPick={(icon) => setFieldValue("serviceCategoryIcon", icon?.name ?? 'gavel')}
                      buttonSize="sm"
                      label={
                        <>
                          <Icon>{values.serviceCategoryIcon}</Icon>
                          <div style={{ padding: "0px 5px" }}>Pick Icon</div>
                        </>
                      }
                    />
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Average Service Time
                  </FormLabel>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    placeholder="in mins"
                    id="duration"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      inputProps: {
                        min: '0',
                      },
                      value: values.duration,
                      onChange: handleChange
                    }}
                  />
                  {errors.duration && touched.duration && (
                    <div style={{ color: "red" }}>{errors.duration}</div>
                  )}
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal} style={{ float: 'left' }}>
                    minutes
                  </FormLabel>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Booking Cut Off
                  </FormLabel>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    placeholder="in days"
                    id="bookingCutOff"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      inputProps: {
                        min: '0',
                      },
                      value: values.bookingCutOff,
                      onChange: handleChange
                    }}
                  />
                  {errors.duration && touched.duration && (
                    <div style={{ color: "red" }}>{errors.duration}</div>
                  )}
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal} style={{ float: 'left' }}>
                    days
                  </FormLabel>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel
                    className={classes.labelHorizontal}
                  >
                    Service Mode
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <GridContainer>
                    <GridItem>
                      <FormControlLabel
                        className={classes.radioContainer}
                        control={
                          <Radio
                            checked={values.mode === serviceMode.SCHEDULE}
                            onChange={handleChange}
                            value={serviceMode.SCHEDULE}
                            name="mode"
                            aria-label="Appointment"
                            icon={<FiberManualRecord className={classes.radioUnchecked} />}
                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                            classes={{
                              checked: classes.radio
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Appointment"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        className={classes.radioContainer}
                        control={
                          <Radio
                            checked={values.mode === serviceMode.QUEUE}
                            onChange={handleChange}
                            value={serviceMode.QUEUE}
                            name="mode"
                            aria-label="Queue"
                            icon={<FiberManualRecord className={classes.radioUnchecked} />}
                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                            classes={{
                              checked: classes.radio
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Queue"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        className={classes.radioContainer}
                        control={
                          <Radio
                            checked={values.mode === serviceMode.BOTH}
                            onChange={handleChange}
                            value={serviceMode.BOTH}
                            name="mode"
                            aria-label="Both"
                            icon={<FiberManualRecord className={classes.radioUnchecked} />}
                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                            classes={{
                              checked: classes.radio
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Both"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        className={classes.radioContainer}
                        control={
                          <Radio
                            checked={values.mode === serviceMode.CALLBACK}
                            onChange={handleChange}
                            value={serviceMode.CALLBACK}
                            name="mode"
                            aria-label="Callback"
                            icon={<FiberManualRecord className={classes.radioUnchecked} />}
                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                            classes={{
                              checked: classes.radio
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Callback"
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Gap between Appointments
                  </FormLabel>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    placeholder="in mins"
                    id="gapBetweenAppointments"
                    name="gapBetweenAppointments"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      inputProps: {
                        min: '0',
                      },
                      onChange: handleChange,
                      value: values.gapBetweenAppointments
                    }}
                  />
                  {errors.gapBetweenAppointments && touched.gapBetweenAppointments ? (
                    <div style={{ color: "red" }}>{errors.gapBetweenAppointments}</div>
                  ) : null}
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal} style={{ float: 'left' }}>
                    minutes
                  </FormLabel>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Re-Entry Offset
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    placeholder=""
                    id="reEntryOffset"
                    name="reEntryOffset"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      inputProps: {
                        min: '0',
                      },
                      onChange: handleChange,
                      value: values.reEntryOffset
                    }}
                  />
                  {errors.reEntryOffset && touched.reEntryOffset ? (
                    <div style={{ color: "red" }}>{errors.reEntryOffset}</div>
                  ) : null}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Late Entry Offset
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    placeholder=""
                    id="lateEntryOffset"
                    name="lateEntryOffset"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      inputProps: {
                        min: '0',
                      },
                      onChange: handleChange,
                      value: values.lateEntryOffset
                    }}
                  />
                  {errors.lateEntryOffset && touched.lateEntryOffset ? (
                    <div style={{ color: "red" }}>{errors.lateEntryOffset}</div>
                  ) : null}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Service Image &nbsp;
                    <Tooltip
                      title="Maximum upload size is 1MB, Aspect Ratio of 1:1"
                    >
                      <InfoIcon
                        style={{ width: '.9rem' }}
                      />
                    </Tooltip>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <ImageUpload
                      currentPictureUrl={defaultImage}
                      aspectRatio={1 / 1}
                      localImageName={'pictureObject'}
                      onPictureChange={handleServicePicture}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
    </>
  )

}
export default forwardRef(ServiceCreate);